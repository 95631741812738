<template>
  <body>
    <div class="container-xl mb-2 p-1">
      <header class="text-center">
        <div class="row">
          <div class="col-md-12">
            <h2>Preview</h2>
          </div><!-- /.col-md-12 -->
        </div><!-- /.row -->
      </header><!-- /.header -->
      <div class="row no-gutters position-relative essay-data">
        <!-- <div class="top-img">
          <img
            class="img-fluid border-radius-img content-left-top img-thumbnail shadow-sm"
            :src="essay.images[0] ? essay.images[0]: 'https://via.placeholder.com/400'"
            alt=""
          >
        </div> -->
        <div class="col-lg-6 bg-light col-print-6">
          <div
            class="content-left  left-1 content-initial"
            style="padding-top:30px !important;"
          >
            <img
              class="img-fluid border-radius-img content-left-top img-thumbnail shadow-sm"
              :src="essay.images[0] ? essay.images[0]: 'https://via.placeholder.com/400'"
              alt=""
              style="float: right;margin-right: -200px; z-index: 20;width: 300px;"
            >
            <!-- eslint-disable vue/no-v-html -->
            <!-- <h2><strong>Introduction</strong></h2> -->
            <!-- <p>

            </p> -->
            <p>
              <!-- {{ i+1 }}.  -->
              {{ essay.introduction.general_statement }}
              <span
                v-for="(main_point,i) in essay.introduction.main_points"
                :key="i"
                style="color:#000000;"
              >
                {{ main_point }}
              </span>
              {{ essay.introduction.main_point }}
              {{ essay.introduction.thesis_statement }}
            </p>
            <!-- eslint-enable vue/no-v-html -->
          </div><!-- /.content-left -->
          <div
            v-for="(body,i) in essay.body"
            :key="i"
            class="content-left content-initial"
          >
            <!-- eslint-disable vue/no-v-html -->
            <!-- <h2><strong>Body {{ i+1 }}</strong></h2> -->
            <p>
              {{ body.connect_to_thesis }}

              {{ body.main_point }}
              <span
                v-for="(evidence,i) in body.evidences"
                :key="i"
                style="color:#000000;"
              >
                {{ evidence }}
              </span>
              {{ body.transition_sentence }}
            </p>
            <!-- <p></p> -->
            <!-- eslint-enable vue/no-v-html -->
          </div><!-- /.content-left -->
          <div class="content-left content-initial">
            <!-- eslint-disable vue/no-v-html -->
            <!-- <h2><strong>Conclusion</strong></h2> -->

            <p>
              {{ essay.conclusion.thesis_statement }}
              <span
                v-for="(main_point,i) in essay.conclusion.main_points"
                :key="i"
                style="color:#000000;"
              >

                {{ main_point }}
                {{ essay.conclusion.closing_sentence }}
                {{ essay.conclusion.conclusion }}
              </span>
            </p>
            <!-- eslint-enable vue/no-v-html -->
          </div><!-- /.content-left -->
        </div><!-- /.col-lg-6 -->
        <div
          class="col-lg-6 col-print-6"
          style="z-index: -1"
        >
          <div
            class="bg-image left-2"
            :style="`background-image: url(${essay.images[1] ? essay.images[1]: 'https://via.placeholder.com/400'});`"
          />
          <div
            class="bg-image p-1 question-img"
            :style="`background-image: url(${essay.images[1] ? essay.images[1]: 'https://via.placeholder.com/400'});`"
          >
            <img
              class="img-fluid border-radius-img"
              :src="essay.images[2] ? essay.images[2]: 'https://via.placeholder.com/400'"
              alt=""
            >
          </div>
        </div>
      </div> <!-- /.row -->
    </div>
  </body>
</template>

<script>

export default {
  props: {
    essay: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.essay-data{
  p{
    font-size: 16px !important;
    color:#000000 !important;
  }
  h2{
    color:#000000 !important;
  }
}
</style>
<style lang="css" scoped>
/* Bootstrap and Google Font */
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;700;900&display=swap");
/* Global CSS */
body {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
}
/* Header */
header {
  background: #29658f;
  padding: 10px 0px;
}
.content-initial {
  float: initial !important;
}
header h2 {
  font-size: 32px;
  font-weight: 600;
  color: #ffffff;
  letter-spacing: 3px;
}
/* BG Image */
.bg-image {
  background-color: #ffffff;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  -ms-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.list-style-wrapper {
  padding-top: 20px;
  padding-bottom: 20px;
}
.list-style-wrapper p {
  font-weight: 600;
}

.list-style {
  text-align: center;
  width: 100%;
  display: block;
  margin: 0 auto;
}

.list-style.list-style-1 {
  margin-bottom: 25px;
}

.list-style-top {
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  background: #29658f;
  padding: 10px;
}
.list-style-bottom {
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  background: #e8937d;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 1px;
}
.list-style-top p {
  font-size: 16px;
  line-height: normal;
  margin-bottom: 0px;
  color: #ffffff;
}
.list-style-bottom p {
  color: #000000 !important;
}
.list-style-2 {
  float: right;
}
.list-style-box-2 {
  margin-top: 30px;
  margin-bottom: 60px;
}
.legend {
  margin-top: 15px;
  text-align: center;
}
.legend p {
  margin-bottom: 5px;
  font-weight: 600;
}
.legend .list-inline li.list-inline-item {
  font-weight: 700;
}
.legend .list-inline li:first-child span {
  width: 12px;
  height: 12px;
  border-radius: 12px;
  background: #29658f;
  display: inline-block;
  margin-right: 5px;
}
.legend .list-inline li:last-child span {
  width: 12px;
  height: 12px;
  border-radius: 12px;
  background: #e8937d;
  display: inline-block;
  margin-right: 5px;
}
.border-radius-img {
  border: 5px solid #29658f;
  border-radius: 100px;
}
.question-img p {
  font-style: italic;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
  margin-top: 8px;
  color: #ffffff;
}
footer {
  background: #29658f;
  padding: 20px;
}
.content-left {
  padding: 15px;
}
.content-left p {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 8px;
}
.content-left span {
  color: #3ab66e;
}
.question-box {
  background: #29658f;
  padding: 15px 15px 15px 20px;
  margin: 0px 20px;
}
.question-box p {
  color: #ffffff;
  font-style: italic;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 5px;
}
.question-ans {
  border-radius: 45px;
  margin: 20px 25px;
  padding: 35px;
  background: #e9a288;
}
.question-ans p {
  font-size: 18px;
  color: #ffffff;
  margin-bottom: 30px;
  font-weight: 600;
}
.question-ans hr {
  border: 1px solid #ffffff;
  margin: 30px 0px;
}
.question-ans hr:last-child {
  margin-bottom: 0;
}

.top-img img {
  border: 1px solid #dee2e6;
  margin-bottom: 20px;
  margin-top: 25px;
  max-width: 440px;
}

.row.position-relative {
  z-index: 1;
}

.top-img {
  position: absolute;
  top: 25px;
  z-index: 2;
  width: 100%;
}

.top-img img {
  height: 350px;
  display: block;
  margin: 0 auto;
}

.left-2 {
  padding: 20px;
}
.left-1,
.left-2 {
  padding-top: 400px !important;
}

@page {
  margin: 0;
}
@media print {
  .container,
  .container-xl {
    padding: 0;
    max-width: 100%;
  }
  .container {
    padding: 0;
    max-width: 100%;
  }
  .top-img img {
    height: 450px;
  }
  .left-1,
  .left-2 {
    padding-top: 500px !important;
  }
}

@media print {
  .col-print-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
}

@media print {
  .col-print-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
}

@media print {
  .col-print-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@media print {
  .col-print-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
}

@media print {
  .col-print-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
}

@media print {
  .col-print-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media print {
  .col-print-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
}

@media print {
  .col-print-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
}

@media print {
  .col-print-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
}

@media print {
  .col-print-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
}

@media print {
  .col-print-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
}

@media print {
  .col-print-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

/* Responsive Media Queries  */

/* Max Width With Start Point */
/* Extra large devices (large desktops, 1200px and Down) */
@media (max-width: 1199.98px) {
  .list-style {
    width: 200px;
  }
}

/* Large devices (desktops, 992px and Down) */
@media (max-width: 991.98px) {
  .top-img {
    position: static;
    padding-top: 0px;
  }
  .left-1,
  .left-2 {
    padding-top: 20px !important;
  }
}

/* Medium devices (tablets, 768px and Down) */
@media (max-width: 767.98px) {
  .content-left p {
    font-size: 18px;
    line-height: 26px;
  }
}

@media (min-width: 992px){
  body .content-left {
      width: calc(100vw - (100vw - 100%) - 40px);
  }
}

/* Small devices (landscape phones, 576px and Down) */
@media (max-width: 575.98px) {
  .list-style {
    float: none;
    width: auto;
    max-width: 100%;
    margin-bottom: 25px;
  }
  .list-style-box-2 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .border-radius-img {
    border-radius: 30px;
  }
  .question-ans {
    border-radius: 35px;
    padding: 30px;
  }
  .top-img img {
    height: auto;
  }
}

/* Max Width */
/* Small devices (landscape phones, 480px and down) */
@media (max-width: 480px) {
}

/* Small devices (landscape phones, 414px and down) */
@media (max-width: 414px) {
}

/* Small devices (landscape phones, 384px and down) */
@media (max-width: 384px) {
}

/* Small devices (landscape phones, 360px and down) */
@media (max-width: 360px) {
}

/* Small devices (landscape phones, 360px and down) */
@media (max-width: 320px) {
}
</style>
